import { EOsuServer, IOsu } from './osu';

export interface IDonator {
  amount: number;
  start: string;
  end: string;
}

export enum EVkAccountStatus {
  BANNED = 0,
  NORMAL = 1,
  DONATOR = 2,
  ADMIN = 3,
}

export interface IVkAccount {
  uid?: string;
  vk: {
    id: number;
    status: EVkAccountStatus;
    donator: IDonator[];
    chats: number[];
    city: string | null;
  };
  osu: {
    akatsuki: IOsu | null;
    bancho: IOsu | null;
    gatari: IOsu | null;
    kurikku: IOsu | null;
    ripple: IOsu | null;
    main: EOsuServer | null;
  };
}

export interface ICommand {
  uid: string;
  command: string;
  caption: string;
  description: string;
  aliases: string[];
  usage: string[];
  cooldown: number;
  canUseInDm: boolean;
  isDisabled: boolean;
}

export interface IVkAccountData {
  id: number;
  photo_max_orig: string;
  first_name: string;
  last_name: string;
  domain: string;
  online: boolean;
  can_access_closed: boolean;
  is_closed: boolean;
}

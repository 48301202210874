import React, { Dispatch, SetStateAction } from 'react';

import Modal from '.';

import { createCookieConsent } from 'utils/localStorage';

interface IProps {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CookieModal: React.FC<IProps> = ({ modalOpen, setModalOpen }) => {
  const handleAccept = () => {
    createCookieConsent();
    setModalOpen(false);
  };

  return (
    <Modal
      id="cookies-modal"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Сайт использует файлы cookie 🍪"
      closeButton={false}
      handleClose={false}
    >
      <div className="text-sm mb-5">
        <div className="space-y-2">
          <p>
            Этот веб-сайт хранит данные, такие как файлы cookie, для обеспечения
            основных функций сайта, а также для маркетинга, персонализации и
            аналитики.
          </p>
          <p>
            Нажимая кнопку согласия вы разрешаете использование файлов cookie.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap justify-end space-x-2">
        <button
          className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={handleAccept}
        >
          Я Согласен
        </button>
      </div>
    </Modal>
  );
};

export default CookieModal;

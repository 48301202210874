import React from 'react';
import { IVkAccount, IVkAccountData } from 'types/vk';

interface IProps {
  vkAccount: IVkAccount | undefined;
  selectedAccount: IVkAccountData;
}

export const AccountsPage: React.FC<IProps> = ({
  vkAccount,
  selectedAccount,
}) => {
  return (
    <div className="flex flex-col xl:flex-row xl:space-x-16">
      <div className="space-y-5 mb-8 xl:mb-0">
        <div>
          <h2 className="text-slate-800 font-semibold mb-2">Departments</h2>
          <div className="grid xl:grid-cols-3 gap-4">
            <div className="bg-white p-4 border border-slate-200 rounded-sm shadow-sm">
              <div className="grow flex items-center truncate mb-2">
                <div className="w-8 h-8 shrink-0 flex items-center justify-center bg-slate-700 rounded-full mr-2">
                  <img
                    className="ml-1"
                    src=""
                    width="14"
                    height="14"
                    alt="Icon 03"
                  />
                </div>
                <div className="truncate">
                  <span className="text-sm font-medium text-slate-800">
                    Acme Marketing
                  </span>
                </div>
              </div>
              <div className="text-sm mb-3">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore.
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <a
                    className="text-sm font-medium text-indigo-500 hover:text-indigo-600"
                    href="#0"
                  >
                    View -&gt;
                  </a>
                </div>
              </div>
            </div>

            <div className="bg-white p-4 border border-slate-200 rounded-sm shadow-sm">
              <div className="grow flex items-center truncate mb-2">
                <div className="w-8 h-8 shrink-0 flex items-center justify-center bg-slate-700 rounded-full mr-2">
                  <img
                    className="ml-1"
                    src=""
                    width="14"
                    height="14"
                    alt="Icon 02"
                  />
                </div>
                <div className="truncate">
                  <span className="text-sm font-medium text-slate-800">
                    Acme Product
                  </span>
                </div>
              </div>
              <div className="text-sm mb-3">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore.
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <a
                    className="text-sm font-medium text-indigo-500 hover:text-indigo-600"
                    href="#0"
                  >
                    View -&gt;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Work History */}
        <div>
          <h2 className="text-slate-800 font-semibold mb-2">Work History</h2>
          <div className="bg-white p-4 border border-slate-200 rounded-sm shadow-sm">
            <ul className="space-y-3">
              {/* Item */}
              <li className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:grow flex items-center text-sm">
                  {/* Icon */}
                  <div className="w-8 h-8 rounded-full shrink-0 bg-amber-500 my-2 mr-3">
                    <svg
                      className="w-8 h-8 fill-current text-amber-50"
                      viewBox="0 0 32 32"
                    >
                      <path d="M21 14a.75.75 0 0 1-.75-.75 1.5 1.5 0 0 0-1.5-1.5.75.75 0 1 1 0-1.5 1.5 1.5 0 0 0 1.5-1.5.75.75 0 1 1 1.5 0 1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1 0 1.5 1.5 1.5 0 0 0-1.5 1.5.75.75 0 0 1-.75.75Zm-7 10a1 1 0 0 1-1-1 4 4 0 0 0-4-4 1 1 0 0 1 0-2 4 4 0 0 0 4-4 1 1 0 0 1 2 0 4 4 0 0 0 4 4 1 1 0 0 1 0 2 4 4 0 0 0-4 4 1 1 0 0 1-1 1Z" />
                    </svg>
                  </div>
                  {/* Position */}
                  <div>
                    <div className="font-medium text-slate-800">
                      Senior Product Designer
                    </div>
                    <div className="flex flex-nowrap items-center space-x-2 whitespace-nowrap">
                      <div>Remote</div>
                      <div className="text-slate-400">·</div>
                      <div>April, 2020 - Today</div>
                    </div>
                  </div>
                </div>
                {/* Tags */}
                <div className="sm:ml-2 mt-2 sm:mt-0">
                  <ul className="flex flex-wrap sm:justify-end -m-1">
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                        Marketing
                      </button>
                    </li>
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                        +4
                      </button>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Item */}
              <li className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:grow flex items-center text-sm">
                  {/* Icon */}
                  <div className="w-8 h-8 rounded-full shrink-0 bg-indigo-500 my-2 mr-3">
                    <svg
                      className="w-8 h-8 fill-current text-indigo-50"
                      viewBox="0 0 32 32"
                    >
                      <path d="M8.994 20.006a1 1 0 0 1-.707-1.707l4.5-4.5a1 1 0 0 1 1.414 0l3.293 3.293 4.793-4.793a1 1 0 1 1 1.414 1.414l-5.5 5.5a1 1 0 0 1-1.414 0l-3.293-3.293L9.7 19.713a1 1 0 0 1-.707.293Z" />
                    </svg>
                  </div>
                  {/* Position */}
                  <div>
                    <div className="font-medium text-slate-800">
                      Product Designer
                    </div>
                    <div className="flex flex-nowrap items-center space-x-2 whitespace-nowrap">
                      <div>Milan, IT</div>
                      <div className="text-slate-400">·</div>
                      <div>April, 2018 - April 2020</div>
                    </div>
                  </div>
                </div>
                {/* Tags */}
                <div className="sm:ml-2 mt-2 sm:mt-0">
                  <ul className="flex flex-wrap sm:justify-end -m-1">
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                        Marketing
                      </button>
                    </li>
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                        +4
                      </button>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Item */}
              <li className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:grow flex items-center text-sm">
                  {/* Icon */}
                  <div className="w-8 h-8 rounded-full shrink-0 bg-indigo-500 my-2 mr-3">
                    <svg
                      className="w-8 h-8 fill-current text-indigo-50"
                      viewBox="0 0 32 32"
                    >
                      <path d="M8.994 20.006a1 1 0 0 1-.707-1.707l4.5-4.5a1 1 0 0 1 1.414 0l3.293 3.293 4.793-4.793a1 1 0 1 1 1.414 1.414l-5.5 5.5a1 1 0 0 1-1.414 0l-3.293-3.293L9.7 19.713a1 1 0 0 1-.707.293Z" />
                    </svg>
                  </div>
                  {/* Position */}
                  <div>
                    <div className="font-medium text-slate-800">
                      Product Designer
                    </div>
                    <div className="flex flex-nowrap items-center space-x-2 whitespace-nowrap">
                      <div>Milan, IT</div>
                      <div className="text-slate-400">·</div>
                      <div>April, 2018 - April 2020</div>
                    </div>
                  </div>
                </div>
                {/* Tags */}
                <div className="sm:ml-2 mt-2 sm:mt-0">
                  <ul className="flex flex-wrap sm:justify-end -m-1">
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                        Marketing
                      </button>
                    </li>
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                        +4
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <aside className="xl:min-w-56 xl:w-56 space-y-3">
        <div className="text-sm">
          <h3 className="font-medium text-slate-800">Id</h3>
          <div>{selectedAccount.id}</div>
        </div>
        <div className="text-sm">
          <h3 className="font-medium text-slate-800">Domain</h3>
          <div>{selectedAccount.domain}</div>
        </div>
        <div className="text-sm">
          <h3 className="font-medium text-slate-800">Name</h3>
          <div>
            {selectedAccount.first_name} {selectedAccount.last_name}
          </div>
        </div>
        <div className="text-sm">
          <h3 className="font-medium text-slate-800">Can Access Closed</h3>
          <div>{String(selectedAccount.can_access_closed)}</div>
        </div>
        <div className="text-sm">
          <h3 className="font-medium text-slate-800">Is Closed</h3>
          <div>{String(selectedAccount.is_closed)}</div>
        </div>
      </aside>
    </div>
  );
};

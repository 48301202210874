import React, { useEffect, useRef } from 'react';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';

import moment from 'moment';

import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

import { IHistory } from 'types/web';

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
);

interface IProps {
  title: string;
  actualData: number;
  value: string;
  backgroundColor: string;
  borderColor: string;
  history: IHistory;
}

const LinearChartCard: React.FC<IProps> = ({
  title,
  actualData,
  value,
  backgroundColor,
  borderColor,
  history,
}) => {
  const canvas = useRef<HTMLCanvasElement | null>(null);

  const data = {
    labels: history.dates,
    datasets: [
      {
        fill: true,
        backgroundColor,
        borderColor,
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: borderColor,
        clip: 20,
        data: history.values,
      },
    ],
  };

  useEffect(() => {
    const ctx = canvas.current?.getContext('2d') as any;

    const chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            display: false,
            beginAtZero: false,
          },
          x: {
            type: 'time',
            time: {
              parser: 'DD/MM/YYYY',
              unit: 'month',
            },
            display: false,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: context =>
                moment(context[0].parsed.x).format('DD/MM/YYYY'), // Disable tooltip title
              label: context => String(context.parsed.y),
            },
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
    });

    return () => chart.destroy();
  }, []);

  const calculateDiff = (): number => {
    const prev = history.values[history.values.length - 2];
    const current = history.values[history.values.length - 1];

    if (prev === undefined) return 100;
    else if (prev === current) return 0;
    else return ((current - prev) / prev) * 100;
  };

  const diff = calculateDiff();

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
      <div className="px-5 pt-5">
        <h2 className="text-lg font-semibold text-slate-800 mb-2">{title}</h2>
        <div className="flex items-start">
          {title === 'Общий Опыт' ? (
            <>
              <div className="text-3xl font-bold text-slate-800">
                <Odometer
                  value={actualData.toFixed(0)}
                  format="(.ddd)"
                  duration={500}
                />
                ,
              </div>
              <div className="text-xl font-bold text-slate-600 mr-2 mb-1 mt-auto">
                <Odometer
                  value={String(actualData).split('.')[1]}
                  format=""
                  duration={2000}
                />
              </div>
            </>
          ) : (
            <div className="text-3xl font-bold text-slate-800 mr-2">
              {actualData}
            </div>
          )}
          {diff > 0 ? (
            <div
              className={`text-sm font-semibold text-white px-1.5 ${
                diff > 0 ? 'bg-emerald-500' : 'bg-red-500'
              } rounded-full`}
            >
              {diff > 0 ? '+' : '-'}
              {diff === Infinity ? '100' : diff.toFixed(2)}%
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="grow">
        <canvas
          className="custom__canvas"
          ref={canvas}
          width={389}
          height={128}
        />
      </div>
    </div>
  );
};

export default LinearChartCard;

import Modal from 'components/Modal';
import { firebaseApp } from 'firebaseApp';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ILog } from 'types/logs';
import { IVkAccount } from 'types/vk';

interface IProps {
  selectedAccount: IVkAccount | undefined;
}

export const LogsPage: React.FC<IProps> = ({ selectedAccount }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [currentLog, setCurrentLog] = useState<ILog | undefined>();
  const [logs, setLogs] = useState<ILog[]>([]);

  useEffect(() => {
    const getLogs = async () => {
      if (!selectedAccount) return;
      setLogs(
        await firebaseApp.firestore.getLogsByUserId(selectedAccount.vk.id),
      );
    };

    getLogs();
  }, [selectedAccount]);

  const setShowLog = (log: ILog) => {
    setCurrentLog(log);
    setShowErrorModal(true);
  };

  const getTag = (tag: string, log: ILog): JSX.Element => {
    let className =
      'inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border shadow-sm bg-white duration-150 ease-in-out ';

    if (tag === 'complete')
      className +=
        'border-emerald-500 hover:border-emerald-600 text-emerald-600';
    if (tag === 'bot' || tag === 'regular' || tag === 'axios')
      className += 'border-indigo-500 hover:border-indigo-600 text-indigo-600';
    if (tag === 'error')
      className += 'border-rose-500 hover:border-rose-600 text-rose-600';

    return (
      <button
        className={className}
        onClick={() => (tag === 'error' ? setShowLog(log) : {})}
      >
        {tag}
      </button>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="space-y-5 mb-8 xl:mb-0">
        <div>
          <div className="bg-white p-4 border border-slate-200 rounded-sm shadow-sm">
            <ul className="space-y-3">
              {logs.map((log, index) => {
                return (
                  <li
                    className="sm:flex sm:items-center sm:justify-between"
                    key={index}
                  >
                    <div className="sm:grow flex items-center text-sm">
                      <div className="w-8 h-8 rounded-full shrink-0 bg-amber-500 my-2 mr-3">
                        <svg
                          className="w-8 h-8 fill-current text-amber-50"
                          viewBox="0 0 32 32"
                        >
                          <path d="M21 14a.75.75 0 0 1-.75-.75 1.5 1.5 0 0 0-1.5-1.5.75.75 0 1 1 0-1.5 1.5 1.5 0 0 0 1.5-1.5.75.75 0 1 1 1.5 0 1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1 0 1.5 1.5 1.5 0 0 0-1.5 1.5.75.75 0 0 1-.75.75Zm-7 10a1 1 0 0 1-1-1 4 4 0 0 0-4-4 1 1 0 0 1 0-2 4 4 0 0 0 4-4 1 1 0 0 1 2 0 4 4 0 0 0 4 4 1 1 0 0 1 0 2 4 4 0 0 0-4 4 1 1 0 0 1-1 1Z" />
                        </svg>
                      </div>
                      <div>
                        <div className="font-medium text-slate-800">
                          {log.title}
                        </div>
                        <div className="flex flex-nowrap items-center space-x-2 whitespace-nowrap">
                          <div>
                            {moment(log.date).format('DD/MM/YYYY HH:mm:ss')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:ml-2 mt-2 sm:mt-0">
                      <ul className="flex flex-wrap sm:justify-end -m-1">
                        {log.tags.map((tag, tagIndex) => {
                          return (
                            <li className="m-1" key={tagIndex}>
                              {getTag(tag, log)}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      <Modal
        id="feedback-modal"
        modalOpen={showErrorModal}
        setModalOpen={setShowErrorModal}
        title={`Ошибка ${currentLog?.uid}`}
      >
        <div className="pt-4 pb-1">
          <div className="text-sm">
            <div className="font-medium text-slate-800 mb-2">Message</div>
            <div className="space-y-2">
              <p>{currentLog?.text}</p>
            </div>
          </div>
          <div className="text-sm">
            <div className="font-medium text-slate-800 mb-2">Chat</div>
            <div className="space-y-2">
              <p>{currentLog?.chat}</p>
            </div>
          </div>
          <div className="text-sm">
            <div className="font-medium text-slate-800 mb-2">Error</div>
            <div className="space-y-2">
              <p>{currentLog?.error?.name}</p>
              <p>{currentLog?.error?.message}</p>
              <p>{currentLog?.error?.stack}</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

import React from 'react';

const LoaderBlock = () => {
  return (
    <div className="absolute w-full h-full flex items-center bg-gray-500 bg-opacity-50 justify-center z-1002">
      <div className="w-56 h-56 border-t-4 border-b-4 border-indigo-900 rounded-full animate-spin"></div>
    </div>
  );
};

export default LoaderBlock;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setSettingsOpen } from 'actions/actions';
import { selectSettings, selectSettingsOpen } from 'actions/selectors';

import { firebaseApp } from 'firebaseApp';

import LoaderBlock from 'components/Loader/block';

const stateSelector = createStructuredSelector({
  settingsViewOpen: selectSettingsOpen(),
  settings: selectSettings(),
});

const SettingsPanel = () => {
  const { settings, settingsViewOpen } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const closeBtn = useRef(null);
  const panelContent = useRef(null);

  const setSettingsViewOpen = (state: boolean) => {
    dispatch(setSettingsOpen(state));
  };

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !settingsViewOpen ||
        //@ts-ignore
        (panelContent.current.contains(target) &&
          //@ts-ignore
          !closeBtn.current.contains(target))
      )
        return;
      setSettingsViewOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!settingsViewOpen || keyCode !== 27) return;
      setSettingsViewOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const [webIsWorking, setWebIsWorking] = useState(settings.webIsWorking);

  const handleSaveChanges = async () => {
    setLoading(true);
    await firebaseApp.firestore.updateSettings(webIsWorking);
    setLoading(false);
  };

  useEffect(() => {
    setWebIsWorking(settings.webIsWorking);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsViewOpen]);

  return (
    <div
      ref={panelContent}
      className={`fixed inset-0 sm:left-auto z-20 transform transition-transform duration-200 ease-in-out ${
        settingsViewOpen ? 'translate-x-' : 'translate-x-full'
      }`}
    >
      <div className="sticky top-16 bg-slate-50 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-slate-200 w-full sm:w-[390px] h-[calc(100vh-64px)]">
        {loading && <LoaderBlock />}
        <button
          ref={closeBtn}
          onClick={() => setSettingsViewOpen(false)}
          className="absolute top-0 right-0 mt-6 mr-6 group p-2"
        >
          <svg
            className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
          </svg>
        </button>
        <div className="grow">
          <div className="p-6 space-y-6">
            <h2 className="text-2xl text-slate-800 font-bold mb-5">
              Настройки сайта
            </h2>

            <section>
              <ul>
                <li className="flex justify-between items-center py-3 border-b border-slate-200">
                  <div>
                    <div className="text-slate-800 font-semibold">
                      Работа сайта
                    </div>
                    <div className="text-sm">
                      Включает или выключает доступ к функционалу сайта (за
                      исключением авторизации и настроек)
                    </div>
                  </div>
                  <div className="flex items-center ml-4">
                    <div className="text-sm text-slate-400 italic mr-2">
                      {webIsWorking ? 'On' : 'Off'}
                    </div>
                    <div className="form-switch">
                      <input
                        type="checkbox"
                        id="web"
                        className="sr-only"
                        checked={webIsWorking}
                        onChange={() => setWebIsWorking(!webIsWorking)}
                      />
                      <label className="bg-red-400" htmlFor="web">
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </section>
          </div>

          <footer>
            <div className="flex flex-col px-6 py-5 border-t border-slate-200">
              <div className="flex self-end">
                <button
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                  onClick={handleSaveChanges}
                >
                  Сохранить изменения
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;

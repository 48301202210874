import superagent from 'superagent';
import { firebaseApp } from 'firebaseApp';

const BASE_URL = 'https://europe-west2-seneal-bot.cloudfunctions.net/web';

export default class OSU {
  private firebase = firebaseApp;

  public async deleteAccount(server: string): Promise<void> {
    await superagent.delete(`${BASE_URL}/osu/delete_account`).send({
      token: await this.firebase.auth.getToken(),
      server,
    });
  }
}

import { Timestamp } from 'firebase/firestore';

export interface ISettings {
  admins: number[];
  webIsWorking: boolean;
}

export interface ICountry {
  code: string;
  count: number;
}

export interface IBotStatistics {
  vkAccounts: number;
  osuAccounts: number;
  scores: number;
  totalExperience: number;
  customCommands: number;
  supportersCountries: ICountry[];
  chats: number;
}

export interface IHistory {
  values: number[];
  dates: string[];
}

export interface IBotStatisticsHistory extends IBotStatistics {
  uid: string;
  createdAt: Timestamp;
}

interface IAuthOsu {
  id: number;
  username: string;
  oauth: boolean;
}

export interface IAuthUser {
  vk: {
    id: number;
    avatar: string;
    first_name: string;
    last_name: string;
    domain: string;
  };
  osu: {
    akatsuki: IAuthOsu | null;
    bancho: IAuthOsu | null;
    kurikku: IAuthOsu | null;
    ripple: IAuthOsu | null;
    gatari: IAuthOsu | null;
  };
  uid: string;
}

export interface IAuthIP {
  asn: string;
  city: string;
  continent_code: string;
  country: string;
  country_area: number;
  country_calling_code: string;
  country_capital: string;
  country_code: string;
  country_code_iso3: string;
  country_name: string;
  country_population: number;
  country_tld: string;
  currency: string;
  currency_name: string;
  in_eu: boolean;
  ip: string;
  languages: string;
  latitude: number;
  longitude: string;
  org: string;
  postal: string;
  region: string;
  region_code: string;
  timezone: string;
  utc_offset: string;
  version: string;
}

export interface IVkOnlineObject {
  name: string;
  surname: string;
  avatar: string;
}

export interface IVkOnline extends IVkOnlineObject {
  id: number;
}

export enum EGroup {
  ALL = 'Все Аккаунты',
  ACTIVE = 'Онлайн',
  DONATOR = 'Донатеры',
  BANNED = 'Заблокированные',
  ADMINS = 'Администраторы',
}

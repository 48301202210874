import { FirebaseApp } from 'firebase/app';

import {
  Database,
  getDatabase,
  onDisconnect,
  onValue,
  ref,
  set,
} from 'firebase/database';
import { IVkOnline, IVkOnlineObject } from 'types/web';

export default class DatabaseService {
  private database: Database;

  constructor(firebaseApp: FirebaseApp) {
    this.database = getDatabase(firebaseApp);
  }

  public setOnline(uid: number, name: string, surname: string, avatar: string) {
    const reference = ref(this.database, 'online/' + uid);

    onValue(reference, snapshot => {
      const value = snapshot.val();
      if (!value) set(reference, { name, surname, avatar });
      onDisconnect(reference).remove();
    });
  }

  public subscribeOnline(onChange: (online: IVkOnline[]) => void) {
    const reference = ref(this.database, 'online');

    onValue(reference, snapshot => {
      const online: IVkOnline[] = [];
      const values = snapshot.val();

      if (values)
        for (const [key, value] of Object.entries(values)) {
          online.push({ id: Number(key), ...(value as IVkOnlineObject) });
        }

      onChange(online);
    });
  }

  public subscribeBotOnline(onChange: (online: boolean) => void) {
    const reference = ref(this.database, 'botOnline');

    onValue(reference, snapshot => {
      onChange(Boolean(snapshot.val()));
    });
  }

  public subscribeBotOnlineDev(onChange: (online: boolean) => void) {
    const reference = ref(this.database, 'botOnlineDev');

    onValue(reference, snapshot => {
      onChange(Boolean(snapshot.val()));
    });
  }
}

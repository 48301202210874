import { action } from 'typesafe-actions';

import { ActionTypes } from './constants';

import { IAuthUser, IVkOnline } from 'types/web';
import { ISettings } from 'types/web';
import { IVkAccount } from 'types/vk';

export const setLoading = (isLoading: boolean) =>
  action(ActionTypes.SET_LOADING, { isLoading });

export const setIsGetAuth = (isGetAuth: boolean) =>
  action(ActionTypes.SET_IS_GET_AUTH, { isGetAuth });

export const setCurrentAccount = (account: IAuthUser | undefined) =>
  action(ActionTypes.SET_CURRENT_ACCOUNT, { account });

export const setVkAccount = (account: IVkAccount | undefined) =>
  action(ActionTypes.SET_VK_ACCOUNT, { account });

export const setSettings = (settings: ISettings) =>
  action(ActionTypes.SET_SETTINGS, { settings });

export const setSettingsOpen = (isOpen: boolean) =>
  action(ActionTypes.SET_SETTINGS_OPEN, { isOpen });

export const setOnline = (online: IVkOnline[]) =>
  action(ActionTypes.SET_ONLINE, { online });

import React, { Dispatch, SetStateAction } from 'react';

import Modal from '.';
import Tooltip from 'components/Tooltip';

import { ICommand } from 'types/vk';

interface IProps {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  command: ICommand;
}

const ViewCommandModal: React.FC<IProps> = ({
  modalOpen,
  setModalOpen,
  command,
}) => {
  return (
    <Modal id="command-modal" modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="mb-2 text-center">
        <div className="text-lg font-semibold text-slate-800">
          Команда {command.command}
        </div>
      </div>
      <div className="text-center">
        <div className="text-sm mb-5">{command.description}</div>
        <div className="space-y-3">
          <div>
            <div className="mb-2 text-center">
              <div className="flex items-center justify-center text-lg font-semibold text-slate-800">
                <span>Синтаксис</span>
                <Tooltip className="ml-3" size="lg" position="bottom">
                  <div className="text-sm text-slate-600">
                    <p>
                      Аргумент, указанный между символами "[]" является
                      обязательным
                    </p>
                    <p>
                      Аргумент, указанный между символами "{`<>`}" не является
                      обязательным
                    </p>
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="text-center">{command.caption}</div>
          </div>

          <div>
            <div className="mb-2 text-center">
              <div className="text-lg font-semibold text-slate-800">
                Варианты использования
              </div>
            </div>
            <div className="text-center">
              {command.usage.map((usage, index) => {
                return (
                  <div className="text-sm mb-5" key={index}>
                    {usage}
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <div className="mb-2 text-center">
              <div className="text-lg font-semibold text-slate-800">
                Перезарядка -{' '}
                <span className="text-indigo-500">{command.cooldown} сек.</span>
              </div>
            </div>
            <div className="text-center">
              <div className="text-sm mb-5">
                <div className="flex justify-center space-x-3">
                  {!command.isDisabled ? (
                    <div className="text-sm inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                      Работает
                    </div>
                  ) : (
                    <div className="text-sm inline-flex font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
                      Отключена
                    </div>
                  )}
                  {command.canUseInDm ? (
                    <div className="text-sm inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                      Личные Сообщения
                    </div>
                  ) : (
                    <div className="text-sm inline-flex font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
                      Личные Сообщения
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewCommandModal;

import React, { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCurrentAccount,
  selectSettings,
  selectVkAccount,
} from 'actions/selectors';

import Header from 'components/Header';

import Sidebar from 'components/Sidebar';

import Tooltip from 'components/Tooltip';
import { setLoading } from 'actions/actions';

import NotWorked from 'components/NotWorked';

import { api } from 'api';
import { EOsuServer } from 'types/osu';

const stateSelector = createStructuredSelector({
  settings: selectSettings(),
  currentUser: selectCurrentAccount(),
  vkAccount: selectVkAccount(),
});

export const Settings = () => {
  const { settings, currentUser, vkAccount } = useSelector(stateSelector);

  const dispatch = useDispatch();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [seeTooltip, setSeeTooltip] = useState(false);

  const handleCreateAuth = () => {
    document.location.href =
      'https://osu.ppy.sh/oauth/authorize?scope=public&response_type=code&redirect_uri=https://bot.seneal.dev/auth/osu&client_id=17153';
  };

  const handleDeleteAuth = async () => {
    dispatch(setLoading(true));
    await api.auth.deleteOsuAuth();
    dispatch(setLoading(false));
  };

  const handleDeleteAccount = async (server: string) => {
    dispatch(setLoading(true));
    await api.osu.deleteAccount(server.toLowerCase());
    dispatch(setLoading(false));
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        currentUser={currentUser}
      />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="h-full">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {!settings.webIsWorking ? (
              <NotWorked />
            ) : (
              <div className="grow">
                <div className="p-6">
                  <h2 className="text-2xl text-slate-800 font-bold mb-5">
                    Интеграции
                  </h2>
                  <div className="mb-6">
                    <div className="mb-4 border-b border-slate-200">
                      <ul className="text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
                        <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                          <a
                            className="text-indigo-500 whitespace-nowrap"
                            href="#0"
                          >
                            osu!
                          </a>
                        </li>
                        <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8 cursor-not-allowed">
                          <a
                            className="text-slate-500 hover:text-slate-600 whitespace-nowrap cursor-not-allowed"
                            href="#0"
                          >
                            Vk
                          </a>
                        </li>
                        <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8 cursor-not-allowed">
                          <a
                            className="text-slate-500 hover:text-slate-600 whitespace-nowrap cursor-not-allowed"
                            href="#0"
                          >
                            Discord
                          </a>
                        </li>
                        <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8 cursor-not-allowed">
                          <a
                            className="text-slate-500 hover:text-slate-600 whitespace-nowrap cursor-not-allowed"
                            href="#0"
                          >
                            Telegram
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <section className="pb-6 border-b border-slate-200">
                    <div className="grid grid-cols-12 gap-6">
                      {(
                        Object.keys(EOsuServer) as Array<
                          keyof typeof EOsuServer
                        >
                      ).map((key, index) => {
                        return (
                          <div
                            className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-slate-200"
                            key={index}
                          >
                            <div className="flex flex-col h-full p-5">
                              <div className="grow">
                                <header className="flex items-center mb-4">
                                  <img
                                    src={require(`../../images/servers/${key.toLowerCase()}.png`)}
                                    alt={key}
                                    className="w-10 h-10 shrink-0 mr-3"
                                  />
                                  <h3 className="text-lg text-slate-800 font-semibold">
                                    {currentUser?.osu[key.toLowerCase()]
                                      ?.username || 'Не Привязан'}
                                  </h3>
                                </header>
                              </div>
                              <footer className="mt-4">
                                <div className="flex flex-wrap justify-between items-center">
                                  <div className="flex space-x-3">
                                    {vkAccount?.osu[key.toLowerCase()] ? (
                                      <button
                                        className="btn-sm bg-rose-500 hover:bg-rose-600 text-white shadow-sm flex items-center"
                                        onClick={() => handleDeleteAccount(key)}
                                      >
                                        <span>Отвязать</span>
                                      </button>
                                    ) : (
                                      <button
                                        className="btn-sm btn-green"
                                        disabled
                                      >
                                        <span>Привязать</span>
                                      </button>
                                    )}

                                    {key === 'BANCHO' ? (
                                      currentUser?.osu[key.toLowerCase()]
                                        ?.oauth ? (
                                        <button
                                          className="btn-sm bg-rose-500 hover:bg-rose-600 text-white shadow-sm flex items-center disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                                          onClick={handleDeleteAuth}
                                        >
                                          <span>Удалить OAuth</span>
                                        </button>
                                      ) : (
                                        <>
                                          <button
                                            disabled={!seeTooltip}
                                            className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm flex items-center disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                                            onClick={handleCreateAuth}
                                          >
                                            <span>OAuth</span>
                                          </button>

                                          <div
                                            className="ml-2 my-auto"
                                            onMouseOver={() =>
                                              setTimeout(() => {
                                                setSeeTooltip(true);
                                              }, 3000)
                                            }
                                          >
                                            <Tooltip className="" size="lg">
                                              <div className="text-sm text-slate-600">
                                                При нажатии на эту кнопку Вы
                                                будете перенаправлены на
                                                страницу авторизации на сайте
                                                osu!. После этого бот получит
                                                Ваш токен, который будет
                                                использоваться в дальнейшем,
                                                убрать авторизацию можно будет
                                                нажав по новой кнопке.
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  <div className="flex space-x-3">
                                    {key === 'BANCHO' ? (
                                      currentUser?.osu[key.toLowerCase()]
                                        ?.oauth ? (
                                        <div className="text-sm inline-flex font-medium bg-indigo-100 text-indigo-600 rounded-full text-center px-2.5 py-1">
                                          OAuth
                                        </div>
                                      ) : (
                                        <div className="text-sm inline-flex font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
                                          OAuth
                                        </div>
                                      )
                                    ) : (
                                      <></>
                                    )}

                                    {vkAccount?.osu[key.toLowerCase()] ? (
                                      <div className="text-sm inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                                        Связан
                                      </div>
                                    ) : (
                                      <div className="text-sm inline-flex font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
                                        Не Связан
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </footer>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

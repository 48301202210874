import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { firebaseApp } from 'firebaseApp';

import axios from 'axios';

import {
  setCurrentAccount,
  setIsGetAuth,
  setLoading,
  setOnline,
  setSettings,
  setVkAccount,
} from 'actions/actions';

import { Main } from 'containers/Main';

import Security from 'containers/Security';
import { VkAuth } from 'containers/Auth/vk';
import { OsuAth } from 'containers/Auth/osu';
import { Commands } from 'containers/Commands';

import SettingsPanel from 'components/Settings';
import Loader from 'components/Loader';
import CookieModal from 'components/Modal/cookie';
import { PrivateRoute } from 'components/PrivateRoute';

import { api } from '../../api';
import { getCookieConsent } from 'utils/localStorage';
import { IAuthIP } from 'types/web';
import Users from 'containers/Users';
import { Settings } from 'containers/Settings';

const App = () => {
  const dispatch = useDispatch();

  const [cookieModalOpen, setCookieModalOpen] = useState(false);

  useEffect(() => {
    const cookieConsent = getCookieConsent();
    if (!cookieConsent) setCookieModalOpen(true);

    dispatch(setLoading(true));

    let unsubscribeVkAccount;

    firebaseApp.auth.handleUserAuth(async user => {
      if (user) {
        dispatch(setIsGetAuth(false));
        const auth = await api.auth.getAuth();

        if (auth) {
          firebaseApp.database.setOnline(
            auth.vk.id,
            auth.vk.first_name,
            auth.vk.last_name,
            auth.vk.avatar,
          );

          unsubscribeVkAccount = firebaseApp.firestore.vkAccountSubscribe(
            auth.vk.id,
            account => {
              dispatch(setVkAccount(account));
              dispatch(setCurrentAccount(auth));
            },
          );

          try {
            const response = await axios.get('https://ipapi.co/json/');
            const ipData = response.data as IAuthIP;

            await firebaseApp.firestore.updateVkSession(
              auth.uid,
              auth.vk.id,
              ipData,
            );
          } catch (error) {
            await firebaseApp.firestore.updateVkSession(
              auth.uid,
              auth.vk.id,
              null,
            );
          }
        } else {
          dispatch(setVkAccount(undefined));
          dispatch(setCurrentAccount(undefined));
        }

        dispatch(setIsGetAuth(true));
      } else {
        dispatch(setVkAccount(undefined));
        dispatch(setCurrentAccount(undefined));
      }

      dispatch(setLoading(false));
    });

    const unsubscribeSettings = firebaseApp.firestore.settingsSubscribe(
      settings => {
        dispatch(setSettings(settings));
      },
    );

    firebaseApp.database.subscribeOnline(online => {
      dispatch(setOnline(online));
    });

    return () => {
      unsubscribeSettings();
      unsubscribeVkAccount();
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/commands" element={<Commands />} />
          <Route path="/" element={<PrivateRoute type="auth" />}>
            <Route path="/security" element={<Security />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route path="/" element={<PrivateRoute type="admin" />}>
            <Route path="/users" element={<Users />} />
          </Route>
          <Route path="/auth">
            <Route path="vk" element={<VkAuth />} />
            <Route path="osu" element={<OsuAth />} />
          </Route>
        </Routes>
      </BrowserRouter>

      <CookieModal
        modalOpen={cookieModalOpen}
        setModalOpen={setCookieModalOpen}
      />
      <Loader />
      <SettingsPanel />
    </>
  );
};

export default App;

import React, { Dispatch, SetStateAction, useState } from 'react';

import { UsersGroupDropDown } from './Dropdown';

import { EVkAccountStatus, IVkAccount, IVkAccountData } from 'types/vk';
import { EGroup } from 'types/web';

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  vkAccounts: IVkAccount[];
  vkAccountsData: IVkAccountData[];
  selectedAccount: IVkAccountData | undefined;
  setSelectedAccount: Dispatch<SetStateAction<IVkAccountData | undefined>>;
}

export const UsersSidebar: React.FC<IProps> = ({
  open,
  setOpen,
  vkAccounts,
  vkAccountsData,
  selectedAccount,
  setSelectedAccount,
}) => {
  const [selectedGroup, setSelectedGroup] = useState<EGroup>(EGroup.ALL);
  const [search, setSearch] = useState<string>('');

  const filterAccounts = (): IVkAccountData[] => {
    let filteredAccounts = vkAccountsData;

    if (selectedGroup === EGroup.ACTIVE)
      filteredAccounts = vkAccountsData.filter(a => a.online);
    if (selectedGroup === EGroup.DONATOR)
      filteredAccounts = vkAccountsData.filter(
        a =>
          vkAccounts.find(a1 => a.id === a1.vk.id)?.vk.status ===
          EVkAccountStatus.DONATOR,
      );
    if (selectedGroup === EGroup.BANNED)
      filteredAccounts = vkAccountsData.filter(
        a =>
          vkAccounts.find(a1 => a.id === a1.vk.id)?.vk.status ===
          EVkAccountStatus.BANNED,
      );
    if (selectedGroup === EGroup.ADMINS)
      filteredAccounts = vkAccountsData.filter(
        a =>
          vkAccounts.find(a1 => a.id === a1.vk.id)?.vk.status ===
          EVkAccountStatus.ADMIN,
      );

    if (search.length) {
      filteredAccounts = filteredAccounts.filter(
        a =>
          a.first_name.toLowerCase().includes(search) ||
          a.last_name.toLowerCase().includes(search) ||
          a.id.toString().includes(search) ||
          a.domain.toLowerCase().includes(search),
      );
    }

    return filteredAccounts;
  };

  const setAccount = (account: IVkAccountData) => {
    setSelectedAccount(account);
    setOpen(false);
  };

  return (
    <div
      id="profile-sidebar"
      className={`absolute z-20 top-0 bottom-0 w-full md:w-auto md:static md:top-auto md:bottom-auto -mr-px md:translate-x-0 transition-transform duration-200 ease-in-out ${
        open ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="sticky top-16 bg-white overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-r border-slate-200 md:w-72 xl:w-80 h-[calc(100vh-64px)]">
        <div>
          <div className="sticky top-0 z-10">
            <div className="flex items-center bg-white border-b border-slate-200 px-5 h-16">
              <div className="w-full flex items-center justify-between">
                <UsersGroupDropDown
                  selectedGroup={selectedGroup}
                  setSelectedGroup={setSelectedGroup}
                />
              </div>
            </div>
          </div>
          <div className="px-5 py-4">
            <form className="relative">
              <label htmlFor="profile-search" className="sr-only">
                Search
              </label>
              <input
                id="profile-search"
                className="form-input w-full focus:border-slate-300"
                type="search"
                placeholder="Поиск..."
                value={search}
                onChange={e => setSearch(e.target.value.toLowerCase())}
              />
            </form>
            <div className="mt-4">
              <div className="text-xs font-semibold text-slate-400 uppercase mb-3">
                Найдено Аккаунтов: {filterAccounts().length}
              </div>
              <ul className="mb-6 mt-2">
                {filterAccounts().map(account => {
                  return (
                    <li className="-mx-2">
                      <button
                        className={`w-full p-2 rounded" ${
                          selectedAccount?.id === account.id && 'bg-indigo-100'
                        }`}
                        onClick={() => setAccount(account)}
                      >
                        <div className="flex items-center">
                          <div className="relative mr-2">
                            <img
                              className="w-8 h-8 rounded-full"
                              src={account.photo_max_orig}
                              width="32"
                              height="32"
                              alt="User"
                            />
                            {account.online ? (
                              <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-emerald-500 border-2 border-white rounded-full"></div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="truncate">
                            <span className="text-sm font-medium text-slate-800">
                              {account.first_name} {account.last_name}
                            </span>
                          </div>
                        </div>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

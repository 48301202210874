import superagent from 'superagent';
import { firebaseApp } from 'firebaseApp';

import { IVkAccountData } from 'types/vk';

const BASE_URL = 'https://europe-west2-seneal-bot.cloudfunctions.net/web';

export default class VK {
  private firebase = firebaseApp;

  public async getUsers(users: string[] | number[]): Promise<IVkAccountData[]> {
    try {
      const response = await superagent
        .post(
          `${BASE_URL}/vk/get_users?token=${await this.firebase.auth.getToken()}`,
        )
        .send({ users: users.join(',') });

      return response.status === 200 ? (response.body as IVkAccountData[]) : [];
    } catch (error) {
      return [];
    }
  }
}

import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Transition } from '@tailwindui/react';

import { EGroup } from 'types/web';

interface IProps {
  selectedGroup: EGroup;
  setSelectedGroup: Dispatch<SetStateAction<EGroup>>;
}

export const UsersGroupDropDown: React.FC<IProps> = ({
  selectedGroup,
  setSelectedGroup,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const container = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        //@ts-ignore
        (dropdown.current.contains(target) &&
          //@ts-ignore
          !closeBtn.current.contains(target))
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const changeGroup = (group: EGroup) => {
    setSelectedGroup(group);
    setDropdownOpen(false);
  };

  return (
    <div className="relative w-full">
      <button
        ref={container}
        className="grow w-full flex items-center justify-between truncate"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="grow w-full flex items-center truncate">
          <div className="truncate">
            <span className="text-sm font-medium">{selectedGroup}</span>
          </div>
        </div>
        <svg
          className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
          viewBox="0 0 12 12"
        >
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </button>
      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-60 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 right-0`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ul
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <li>
            <a
              className="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
              href="#0"
              onClick={() => changeGroup(EGroup.ALL)}
            >
              <div className="flex items-center justify-between">
                <div className="grow flex items-center truncate">
                  <div className="truncate">{EGroup.ALL}</div>
                </div>
                {selectedGroup === EGroup.ALL && (
                  <svg
                    className="w-3 h-3 shrink-0 fill-current text-indigo-500 ml-1"
                    viewBox="0 0 12 12"
                  >
                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                  </svg>
                )}
              </div>
            </a>
          </li>
          <li>
            <a
              className="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
              href="#0"
              onClick={() => changeGroup(EGroup.ACTIVE)}
            >
              <div className="flex items-center justify-between">
                <div className="grow flex items-center truncate">
                  <div className="truncate">{EGroup.ACTIVE}</div>
                </div>
                {selectedGroup === EGroup.ACTIVE && (
                  <svg
                    className="w-3 h-3 shrink-0 fill-current text-indigo-500 ml-1"
                    viewBox="0 0 12 12"
                  >
                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                  </svg>
                )}
              </div>
            </a>
          </li>
          <li>
            <a
              className="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
              href="#0"
              onClick={() => changeGroup(EGroup.DONATOR)}
            >
              <div className="flex items-center justify-between">
                <div className="grow flex items-center truncate">
                  <div className="truncate">{EGroup.DONATOR}</div>
                </div>
                {selectedGroup === EGroup.DONATOR && (
                  <svg
                    className="w-3 h-3 shrink-0 fill-current text-indigo-500 ml-1"
                    viewBox="0 0 12 12"
                  >
                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                  </svg>
                )}
              </div>
            </a>
          </li>
          <li>
            <a
              className="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
              href="#0"
              onClick={() => changeGroup(EGroup.BANNED)}
            >
              <div className="flex items-center justify-between">
                <div className="grow flex items-center truncate">
                  <div className="truncate">{EGroup.BANNED}</div>
                </div>
                {selectedGroup === EGroup.BANNED && (
                  <svg
                    className="w-3 h-3 shrink-0 fill-current text-indigo-500 ml-1"
                    viewBox="0 0 12 12"
                  >
                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                  </svg>
                )}
              </div>
            </a>
          </li>
          <li>
            <a
              className="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
              href="#0"
              onClick={() => changeGroup(EGroup.ADMINS)}
            >
              <div className="flex items-center justify-between">
                <div className="grow flex items-center truncate">
                  <div className="truncate">{EGroup.ADMINS}</div>
                </div>
                {selectedGroup === EGroup.ADMINS && (
                  <svg
                    className="w-3 h-3 shrink-0 fill-current text-indigo-500 ml-1"
                    viewBox="0 0 12 12"
                  >
                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                  </svg>
                )}
              </div>
            </a>
          </li>
        </ul>
      </Transition>
    </div>
  );
};

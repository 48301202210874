import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { EVkAccountStatus, IVkAccount, IVkAccountData } from 'types/vk';

import Hat from '../../images/hat.png';
import { AccountsPage } from './Pages/Accounts';
import { LogsPage } from './Pages/Logs';

enum EPage {
  ACCOUNTS,
  LOGS,
  DONATIONS,
}

interface IProps {
  usersSidebarOpen: boolean;
  setUsersSidebarOpen: Dispatch<SetStateAction<boolean>>;
  vkAccount: IVkAccount | undefined;
  selectedAccount: IVkAccountData | undefined;
  actions: ((any) => void)[];
}

export const UsersBody: React.FC<IProps> = ({
  usersSidebarOpen,
  setUsersSidebarOpen,
  vkAccount,
  selectedAccount,
  actions,
}) => {
  const [currentPage, setCurrentPage] = useState<EPage>(EPage.ACCOUNTS);

  return !selectedAccount ? (
    <></>
  ) : (
    <div
      className={`grow flex flex-col md:translate-x-0 transition-transform duration-300 ease-in-out ${
        usersSidebarOpen ? 'translate-x-1/3' : 'translate-x-0'
      }`}
    >
      <div className="relative h-56 bg-slate-200">
        <img
          className="object-cover h-full w-full"
          src={Hat}
          width="979"
          height="220"
          alt="Profile background"
        />
        <button
          className="md:hidden absolute top-4 left-4 sm:left-6 text-white opacity-80 hover:opacity-100"
          onClick={() => setUsersSidebarOpen(!usersSidebarOpen)}
          aria-controls="profile-sidebar"
          aria-expanded={usersSidebarOpen}
        >
          <span className="sr-only">Close sidebar</span>
          <svg
            className="w-6 h-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
          </svg>
        </button>
      </div>

      <div className="relative px-4 sm:px-6 pb-8">
        <div className="-mt-16 mb-6 sm:mb-3">
          <div className="flex flex-col items-center sm:flex-row sm:justify-between sm:items-end">
            <div className="inline-flex -ml-1 -mt-1 mb-4 sm:mb-0">
              <img
                className="rounded-full border-4 border-white w-32 h-32"
                src={selectedAccount.photo_max_orig}
                width="128"
                height="128"
                alt="Avatar"
              />
            </div>

            <div className="flex space-x-2 sm:mb-2">
              {vkAccount?.vk.status === EVkAccountStatus.DONATOR ? (
                <button className="btn-sm btn-red" onClick={actions[2]}>
                  Убрать Донатера
                </button>
              ) : (
                <button className="btn-sm btn-green" onClick={actions[1]}>
                  Выдать Донатера
                </button>
              )}
              {vkAccount?.vk.status === EVkAccountStatus.BANNED ? (
                <button
                  className="btn-sm btn-green"
                  onClick={() => actions[0](false)}
                >
                  Разбанить
                </button>
              ) : (
                <button
                  className="btn-sm btn-red"
                  onClick={() => actions[0](true)}
                >
                  Забанить
                </button>
              )}
            </div>
          </div>
        </div>

        <header className="text-center sm:text-left mb-6">
          <div className="inline-flex items-start mb-2">
            <h1 className="text-2xl text-slate-800 font-bold">
              {selectedAccount.first_name} {selectedAccount.last_name}
            </h1>
          </div>
          <div className="flex flex-wrap justify-center sm:justify-start space-x-4">
            <div className="flex items-center">
              <svg
                className="w-4 h-4 fill-current shrink-0 text-slate-400"
                viewBox="0 0 16 16"
              >
                <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0ZM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12Zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2Z" />
              </svg>
              <a
                className="text-sm font-medium whitespace-nowrap text-indigo-500 hover:text-indigo-600 ml-2"
                href={`https://vk.com/${selectedAccount.domain}`}
              >
                {selectedAccount.domain}
              </a>
            </div>
          </div>
        </header>

        <div className="relative mb-6">
          <div
            className="absolute bottom-0 w-full h-px bg-slate-200"
            aria-hidden="true"
          ></div>
          <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            <li className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className={`block pb-3 whitespace-nowrap border-b-2 ${
                  currentPage === EPage.ACCOUNTS
                    ? 'text-indigo-500 border-indigo-500'
                    : 'text-slate-500 hover:text-slate-600'
                }`}
                href="#0"
                onClick={() => setCurrentPage(EPage.ACCOUNTS)}
              >
                Аккаунты
              </a>
            </li>
            <li className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className={`block pb-3 whitespace-nowrap border-b-2 ${
                  currentPage === EPage.LOGS
                    ? 'text-indigo-500 border-indigo-500'
                    : 'text-slate-500 hover:text-slate-600'
                }`}
                href="#0"
                onClick={() => setCurrentPage(EPage.LOGS)}
              >
                Логи
              </a>
            </li>
            <li className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className={`block pb-3 whitespace-nowrap border-b-2 ${
                  currentPage === EPage.DONATIONS
                    ? 'text-indigo-500 border-indigo-500'
                    : 'text-slate-500 hover:text-slate-600'
                }`}
                href="#0"
                onClick={() => setCurrentPage(EPage.DONATIONS)}
              >
                Донаты
              </a>
            </li>
          </ul>
        </div>

        {currentPage === EPage.ACCOUNTS && (
          <AccountsPage
            selectedAccount={selectedAccount}
            vkAccount={vkAccount}
          />
        )}

        {currentPage === EPage.LOGS && <LogsPage selectedAccount={vkAccount} />}
      </div>
    </div>
  );
};

import { FirebaseApp, initializeApp } from 'firebase/app';

import Auth from 'services/auth';
import DatabaseService from 'services/database';
import FirestoreService from 'services/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyD2f6tRNHxVEx0W9W2Wc0FJuFbA-KqHErM',
  authDomain: 'seneal-bot.firebaseapp.com',
  projectId: 'seneal-bot',
  storageBucket: 'seneal-bot.appspot.com',
  messagingSenderId: '956851396825',
  appId: '1:956851396825:web:27669fb748698bd150dec8',
  measurementId: 'G-RYG00RD2XZ',
  databaseURL:
    'https://seneal-bot-default-rtdb.europe-west1.firebasedatabase.app',
};

export class Firebase {
  private firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

  public firestore: FirestoreService = new FirestoreService(this.firebaseApp);
  public auth: Auth = new Auth(this.firebaseApp);
  public database = new DatabaseService(this.firebaseApp);
}

export const firebaseApp = new Firebase();

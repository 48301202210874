import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { selectCurrentAccount, selectSettings } from 'actions/selectors';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import NotWorked from 'components/NotWorked';
import LinearChartCard from 'components/Card/LinearChartCard';
import { IBotStatistics, IBotStatisticsHistory, IHistory } from 'types/web';
import { firebaseApp } from 'firebaseApp';
import moment from 'moment';
import { hexToRGB, tailwindConfig } from 'utils/charts';
import { BannerStatus } from 'components/Banner';
import { SupportersChartCard } from 'components/Card/SupportersChartCard';

const stateSelector = createStructuredSelector({
  settings: selectSettings(),
  currentUser: selectCurrentAccount(),
});

export const Main = () => {
  const { settings, currentUser } = useSelector(stateSelector);

  const [statistics, setStatistics] = useState<IBotStatistics | undefined>();
  const [history, setHistory] = useState<IBotStatisticsHistory[]>([]);
  const [botOnline, setBotOnline] = useState(false);
  const [botOnlineDev, setBotOnlineDev] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const getStatistics = async () => {
      let storedHistory = await firebaseApp.firestore.getStatisticsHistory();
      storedHistory = storedHistory.sort(
        (a, b) => a.createdAt.seconds - b.createdAt.seconds,
      );

      setHistory(storedHistory);
    };

    getStatistics();

    const unsubscribe = firebaseApp.firestore.statisticsSubscribe(
      statistics => {
        setStatistics(statistics);
      },
    );

    firebaseApp.database.subscribeBotOnline(online => {
      setBotOnline(online);
    });
    firebaseApp.database.subscribeBotOnlineDev(online => {
      setBotOnlineDev(online);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const getHistory = (
    type:
      | 'vkAccounts'
      | 'osuAccounts'
      | 'customCommands'
      | 'scores'
      | 'experience'
      | 'chats',
  ): IHistory => {
    const formatedHistory: IHistory = { dates: [], values: [] };

    for (const day of history) {
      formatedHistory.dates.push(
        moment(day.uid, 'YYYY/MM/DD').format('DD/MM/YYYY'),
      );

      if (type === 'vkAccounts') formatedHistory.values.push(day.vkAccounts);
      else if (type === 'osuAccounts')
        formatedHistory.values.push(day.osuAccounts);
      else if (type === 'customCommands')
        formatedHistory.values.push(day.customCommands);
      else if (type === 'scores') formatedHistory.values.push(day.scores);
      else if (type === 'experience')
        formatedHistory.values.push(day.totalExperience);
      else if (type === 'chats') formatedHistory.values.push(day.chats);
    }

    formatedHistory.dates.push(moment().format('DD/MM/YYYY'));

    if (type === 'vkAccounts')
      formatedHistory.values.push(
        statistics?.vkAccounts ||
        formatedHistory.values[formatedHistory.values.length - 1],
      );
    else if (type === 'osuAccounts')
      formatedHistory.values.push(
        statistics?.osuAccounts ||
        formatedHistory.values[formatedHistory.values.length - 1],
      );
    else if (type === 'customCommands')
      formatedHistory.values.push(
        statistics?.customCommands ||
        formatedHistory.values[formatedHistory.values.length - 1],
      );
    else if (type === 'scores')
      formatedHistory.values.push(
        statistics?.scores ||
        formatedHistory.values[formatedHistory.values.length - 1],
      );
    else if (type === 'experience')
      formatedHistory.values.push(
        statistics?.totalExperience ||
        formatedHistory.values[formatedHistory.values.length - 1],
      );
    else if (type === 'chats')
      formatedHistory.values.push(
        statistics?.chats ||
        formatedHistory.values[formatedHistory.values.length - 1],
      );

    return formatedHistory;
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        currentUser={currentUser}
      />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="h-full">
          {!settings.webIsWorking ? (
            <NotWorked />
          ) : (
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <BannerStatus botOnline={botOnline} botOnlineDev={botOnlineDev} />
              <div className="grid grid-cols-12 gap-6">
                <LinearChartCard
                  title="Аккаунтов Вконтакте"
                  actualData={statistics?.vkAccounts || 0}
                  value=""
                  history={getHistory('vkAccounts')}
                  backgroundColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.08)`}
                  borderColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.9)`}
                />

                <LinearChartCard
                  title="Аккаунтов osu!"
                  actualData={statistics?.osuAccounts || 0}
                  value=""
                  history={getHistory('osuAccounts')}
                  backgroundColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.08)`}
                  borderColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.9)`}
                />

                <LinearChartCard
                  title="Кастомных Команд"
                  actualData={statistics?.customCommands || 0}
                  value=""
                  history={getHistory('customCommands')}
                  backgroundColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.08)`}
                  borderColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.9)`}
                />

                <LinearChartCard
                  title="Сохранено Скоров"
                  actualData={statistics?.scores || 0}
                  value=""
                  history={getHistory('scores')}
                  backgroundColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.08)`}
                  borderColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.9)`}
                />

                <LinearChartCard
                  title="Общий Опыт"
                  actualData={statistics?.totalExperience || 0}
                  value=""
                  history={getHistory('experience')}
                  backgroundColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.08)`}
                  borderColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.9)`}
                />

                <LinearChartCard
                  title="Беседы"
                  actualData={statistics?.chats || 0}
                  value=""
                  history={getHistory('chats')}
                  backgroundColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.08)`}
                  borderColor={`rgba(${hexToRGB(
                    tailwindConfig().theme.colors.indigo[600],
                  )}, 0.9)`}
                />

                <SupportersChartCard
                  actualData={statistics?.supportersCountries || []}
                />
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

import { firebaseApp } from 'firebaseApp';

import Auth from './auth';
import OSU from './osu';
import VK from './vk';

class API {
  private firebase = firebaseApp;

  public auth: Auth = new Auth();
  public vk: VK = new VK();
  public osu: OSU = new OSU();
}

export const api = new API();

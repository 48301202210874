import { FirebaseApp } from 'firebase/app';

import {
  getAuth,
  signInWithCustomToken,
  Auth as FirebaseAuth,
  onAuthStateChanged,
  signOut,
  updateProfile,
  updateEmail,
} from 'firebase/auth';

export default class Auth {
  private auth: FirebaseAuth;

  constructor(firebaseApp: FirebaseApp) {
    this.auth = getAuth(firebaseApp);
  }

  public handleUserAuth(callback: (user: any) => void) {
    onAuthStateChanged(this.auth, user => {
      callback(user);
    });
  }

  public async signIn(token: string): Promise<void> {
    await signInWithCustomToken(this.auth, token);
  }

  public async signOut(): Promise<void> {
    await signOut(this.auth);
  }

  public async getToken(): Promise<string | undefined> {
    const token = await this.auth.currentUser?.getIdToken(true);

    return token;
  }
}

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import { Transition } from '@tailwindui/react';

import NoAvatar from '../../../images/no_avatar.png';

import { selectCurrentAccount } from 'actions/selectors';
import { setCurrentAccount } from 'actions/actions';
import { api } from 'api';

const stateSelector = createStructuredSelector({
  currentAccount: selectCurrentAccount(),
});

const UserDropdown = () => {
  const { currentAccount } = useSelector(stateSelector);

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const container = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        //@ts-ignore
        (dropdown.current.contains(target) &&
          //@ts-ignore
          !closeBtn.current.contains(target))
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleLogout = async () => {
    dispatch(setCurrentAccount(undefined));
    if (currentAccount) await api.auth.deleteAuth(currentAccount.uid);
  };

  return (
    <div className="relative inline-flex">
      {currentAccount ? (
        <>
          <button
            ref={container}
            className="inline-flex justify-center items-center group"
            aria-haspopup="true"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            aria-expanded={dropdownOpen}
          >
            <img
              className="w-8 h-8 rounded-full"
              src={currentAccount.vk.avatar}
              width="32"
              height="32"
              alt="User"
            />
            <div className="flex items-center truncate">
              <span className="truncate ml-2 text-sm font-medium duration-700 group-hover:text-slate-800">
                {currentAccount.vk.first_name} {currentAccount.vk.last_name}
              </span>
              <svg
                className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
                viewBox="0 0 12 12"
              >
                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
              </svg>
            </div>
          </button>

          <Transition
            className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 right-0`}
            show={dropdownOpen}
            enter="transition ease-out duration-200 transform"
            enterFrom="opacity-0 -translate-y-2"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(true)}
              onBlur={() => setDropdownOpen(false)}
            >
              <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
                <div className="font-medium text-slate-800">
                  {currentAccount.vk.first_name} {currentAccount.vk.last_name}
                </div>
              </div>
              <ul>
                <li>
                  <Link
                    className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                    to="/settings"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    Настройки
                  </Link>
                </li>
                <li>
                  <button
                    className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    Выход
                  </button>
                </li>
              </ul>
            </div>
          </Transition>
        </>
      ) : (
        <a
          className="inline-flex justify-center items-center group"
          href="https://oauth.vk.com/oauth/authorize?client_id=51396184&redirect_uri=https://bot.seneal.dev/auth/vk&scope=65537&display=page&response_type=code"
        >
          <img
            className="w-8 h-8 rounded-full"
            src={NoAvatar}
            width="32"
            height="32"
            alt="User"
          />
          <div className="flex items-center truncate">
            <span className="truncate ml-2 text-sm font-medium theme-text">
              Авторизация
            </span>
            {currentAccount && (
              <svg
                className="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400"
                viewBox="0 0 12 12"
              >
                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
              </svg>
            )}
          </div>
        </a>
      )}
    </div>
  );
};

export default UserDropdown;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';

import { firebaseApp } from 'firebaseApp';

import Modal from '.';

import { ICommand } from 'types/vk';

interface IProps {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  command: ICommand;
  commands: ICommand[];
}

const EditCommandModal: React.FC<IProps> = ({
  modalOpen,
  setModalOpen,
  command,
  commands,
}) => {
  const [name, setName] = useState(command.command);
  const [caption, setCaption] = useState(command.caption);
  const [description, setDescription] = useState(command.description);
  const [cooldown, setCooldown] = useState(command.cooldown);
  const [isDisabled, setIsDisabled] = useState(command.isDisabled);
  const [canUseInDm, setCanUseInDm] = useState(command.canUseInDm);
  const [aliases, setAliases] = useState(command.aliases);
  const [newAlias, setNewAlias] = useState('');
  const [usages, setUsages] = useState(command.usage);
  const [newUsage, setNewUsage] = useState('');

  useEffect(() => {
    setName(command.command);
    setCaption(command.caption);
    setDescription(command.description);
    setCooldown(command.cooldown);
    setIsDisabled(command.isDisabled);
    setCanUseInDm(command.canUseInDm);
    setAliases(command.aliases);
    setUsages(command.usage);
  }, [command]);

  const isNewAliasAvailable = (): boolean => {
    if (
      newAlias.startsWith('!') &&
      newAlias.length > 1 &&
      !commands.find(c => c.command === newAlias) &&
      !commands.find(c => c.aliases.includes(newAlias)) &&
      name !== newAlias &&
      !aliases.includes(newAlias)
    )
      return true;

    return false;
  };

  const addAlias = (): void => {
    const newAliases = [...aliases];
    newAliases.push(newAlias);
    setAliases(newAliases);
    setNewAlias('');
  };

  const deleteAlias = (alias: string): void => {
    const findIndex = aliases.findIndex(a => a === alias);
    if (findIndex >= 0) {
      const newAliases = [...aliases];
      newAliases.splice(findIndex, 1);
      setAliases(newAliases);
    }
  };

  const deleteUsage = (usage: string): void => {
    const findIndex = usages.findIndex(a => a === usage);
    if (findIndex >= 0) {
      const newUsages = [...usages];
      newUsages.splice(findIndex, 1);
      setUsages(newUsages);
    }
  };

  const save = async (): Promise<void> => {
    setModalOpen(false);

    command = {
      uid: command.uid,
      command: name,
      caption: caption,
      description: description,
      cooldown: cooldown,
      isDisabled: isDisabled,
      canUseInDm: canUseInDm,
      aliases: aliases,
      usage: usages,
    };

    await firebaseApp.firestore.updateCommand(
      command.uid,
      name,
      caption,
      description,
      cooldown,
      isDisabled,
      canUseInDm,
      aliases,
      usages,
    );
  };

  return (
    <Modal
      id="feedback-modal"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Редактирование команды"
    >
      <div className="py-4">
        <div className="space-y-3">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="name">
              Название <span className="text-rose-500">*</span>
            </label>
            <input
              id="name"
              className="form-input w-full px-2 py-1"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="caption">
              Синтаксис <span className="text-rose-500">*</span>
            </label>
            <input
              id="caption"
              className="form-input w-full px-2 py-1"
              type="text"
              value={caption}
              onChange={e => setCaption(e.target.value)}
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="description"
            >
              Описание <span className="text-rose-500">*</span>
            </label>
            <textarea
              id="description"
              className="form-textarea w-full px-2 py-1"
              value={description}
              onChange={e => setDescription(e.target.value)}
              required
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="dm">
              Перезарядка
            </label>
            <div className="relative">
              <input
                id="cooldown"
                className="form-input w-full pr-10"
                type="number"
                value={cooldown}
                min="0"
                onChange={e => setCooldown(Number(e.target.value))}
              />
              <div className="absolute inset-0 left-auto flex items-center pointer-events-none">
                <span className="text-sm text-slate-400 font-medium px-3">
                  сек.
                </span>
              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="caption">
              Варианты Использования
            </label>
            {usages.map((usage, index) => {
              return (
                <div className="flex gap-2 my-2" key={index}>
                  <input
                    className="form-input w-full px-2 py-1"
                    type="text"
                    value={usage}
                    disabled
                  />
                  <button
                    className="btn cursor-pointer w-12 bg-indigo-500 hover:bg-indigo-600 disabled:bg-gray-500 disabled:cursor-not-allowed text-white"
                    onClick={() => deleteUsage(usage)}
                  >
                    <FaTrash />
                  </button>
                </div>
              );
            })}

            <div className="flex gap-2 my-2">
              <input
                className="form-input w-full px-2 py-1"
                type="text"
                value={newUsage}
                onChange={e => setNewUsage(e.target.value)}
              />
              <button
                disabled={newUsage.length === 0}
                className="btn w-12 cursor-pointer bg-indigo-500 hover:bg-indigo-600 disabled:bg-gray-500 disabled:cursor-not-allowed text-white"
                onClick={addAlias}
              >
                <FaPlus />
              </button>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="caption">
              Алиасы
            </label>
            {aliases.map((alias, index) => {
              return (
                <div className="flex gap-2 my-2" key={index}>
                  <input
                    className="form-input w-full px-2 py-1"
                    type="text"
                    value={alias}
                    disabled
                  />
                  <button
                    className="btn w-12 cursor-pointer bg-indigo-500 hover:bg-indigo-600 disabled:bg-gray-500 disabled:cursor-not-allowed text-white"
                    onClick={() => deleteAlias(alias)}
                  >
                    <FaTrash />
                  </button>
                </div>
              );
            })}

            <div className="flex gap-2 my-2">
              <input
                className="form-input w-full px-2 py-1"
                type="text"
                value={newAlias}
                onChange={e => setNewAlias(e.target.value)}
              />
              <button
                disabled={!isNewAliasAvailable()}
                className="btn w-12 cursor-pointer bg-indigo-500 hover:bg-indigo-600 disabled:bg-gray-500 disabled:cursor-not-allowed text-white"
                onClick={addAlias}
              >
                <FaPlus />
              </button>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="dm">
              Работа в сообщения сообщества
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="dm"
                checked={canUseInDm}
                onChange={e => setCanUseInDm(e.target.checked)}
              />
              <span className="text-sm ml-2">
                {canUseInDm ? 'Разрешена' : 'Запрещена'}
              </span>
            </label>
          </div>
          <div>
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="disabled"
            >
              Статус
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="disabled"
                checked={!isDisabled}
                onChange={e => setIsDisabled(!e.target.checked)}
              />
              <span className="text-sm ml-2">
                {isDisabled ? 'Отключена' : 'Включена'}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div className="py-4 border-t border-slate-200">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            className="btn-sm p-2 bg-indigo-500 hover:bg-indigo-600 text-white"
            onClick={save}
          >
            Сохранить
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditCommandModal;

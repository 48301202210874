import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { selectCurrentAccount, selectSettings } from 'actions/selectors';

import Header from 'components/Header';

import Sidebar from 'components/Sidebar';
import NotWorked from 'components/NotWorked';

import { setLoading } from 'actions/actions';

import { ICommand } from 'types/vk';
import { firebaseApp } from 'firebaseApp';
import EditCommandModal from 'components/Modal/editCommand';
import ViewCommandModal from 'components/Modal/viewCommand';

enum EActionType {
  VIEW,
  EDIT,
}

const stateSelector = createStructuredSelector({
  settings: selectSettings(),
  currentUser: selectCurrentAccount(),
});

export const Commands = () => {
  const { settings, currentUser } = useSelector(stateSelector);

  const isAdmin =
    (currentUser && settings.admins.includes(currentUser.vk.id)) || false;

  const dispatch = useDispatch();
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [commands, setCommands] = useState<ICommand[]>([]);
  const [selectedCommand, setSelectedCommand] = useState<
    ICommand | undefined
  >();
  const [actionType, setActionType] = useState<EActionType | undefined>();

  useEffect(() => {
    const loadCommands = async () => {
      dispatch(setLoading(true));
      const commands = await firebaseApp.firestore.getCommands();
      setCommands(commands);

      const params = new URLSearchParams(location.search);
      const command = params.get('command');

      const findCommand = commands.find(c => c.command === command);

      if (findCommand) {
        setModalOpen(true);
        setActionType(EActionType.VIEW);
        setSelectedCommand(findCommand);
      }

      dispatch(setLoading(false));
    };

    loadCommands();
  }, []);

  const getFilteredCommands = (): ICommand[] => {
    const filteredCommands = commands.filter(
      c =>
        c.aliases.includes(searchString.toLowerCase()) ||
        c.command.includes(searchString.toLowerCase()) ||
        c.description.includes(searchString.toLocaleLowerCase()),
    );

    return searchString.length > 0 ? filteredCommands : commands;
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        currentUser={currentUser}
      />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="h-full">
          {!settings.webIsWorking ? (
            <NotWorked />
          ) : (
            <>
              <div className="relative flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 py-8 lg:py-16 bg-indigo-500 overflow-hidden">
                <div
                  className="absolute pointer-events-none"
                  aria-hidden="true"
                >
                  <svg
                    width="512"
                    height="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <radialGradient
                        cx="50%"
                        cy="50%"
                        fx="50%"
                        fy="50%"
                        r="50%"
                        id="ill-a"
                      >
                        <stop stopColor="#FFF" offset="0%" />
                        <stop stopColor="#FFF" stopOpacity="0" offset="100%" />
                      </radialGradient>
                    </defs>
                    <circle
                      style={{ mixBlendMode: 'overlay' }}
                      cx="588"
                      cy="650"
                      r="256"
                      transform="translate(-332 -394)"
                      fill="url(#ill-a)"
                      fillRule="evenodd"
                      opacity=".48"
                    />
                  </svg>
                </div>
                <div
                  className="absolute pointer-events-none"
                  aria-hidden="true"
                >
                  <svg
                    width="1280"
                    height="361"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <linearGradient
                        x1="50%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                        id="ill2-b"
                      >
                        <stop stopColor="#A5B4FC" offset="0%" />
                        <stop stopColor="#818CF8" offset="100%" />
                      </linearGradient>
                      <linearGradient
                        x1="50%"
                        y1="24.537%"
                        x2="50%"
                        y2="100%"
                        id="ill2-c"
                      >
                        <stop stopColor="#4338CA" offset="0%" />
                        <stop
                          stopColor="#6366F1"
                          stopOpacity="0"
                          offset="100%"
                        />
                      </linearGradient>
                      <path id="ill2-a" d="m64 0 64 128-64-20-64 20z" />
                      <path id="ill2-e" d="m40 0 40 80-40-12.5L0 80z" />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <g transform="rotate(51 -92.764 293.763)">
                        <mask id="ill2-d" fill="#fff">
                          <use xlinkHref="#ill2-a" />
                        </mask>
                        <use fill="url(#ill2-b)" xlinkHref="#ill2-a" />
                        <path
                          fill="url(#ill2-c)"
                          mask="url(#ill2-d)"
                          d="M64-24h80v152H64z"
                        />
                      </g>
                      <g transform="rotate(-51 618.151 -940.113)">
                        <mask id="ill2-f" fill="#fff">
                          <use xlinkHref="#ill2-e" />
                        </mask>
                        <use fill="url(#ill2-b)" xlinkHref="#ill2-e" />
                        <path
                          fill="url(#ill2-c)"
                          mask="url(#ill2-f)"
                          d="M40.333-15.147h50v95h-50z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="relative w-full max-w-2xl mx-auto text-center">
                  <div className="mb-5">
                    <h1 className="text-2xl md:text-3xl text-white font-bold">
                      👋 Привет! Интересует какая-то команда?
                    </h1>
                  </div>
                  <form className="relative">
                    <label htmlFor="search" className="sr-only">
                      Поиск
                    </label>
                    <input
                      id="search"
                      placeholder="Поиск..."
                      className="form-input px-auto py-auto text-center focus:border-slate-300 w-full"
                      onChange={e => setSearchString(e.target.value)}
                    />
                  </form>
                </div>
              </div>

              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <div className="space-y-8">
                  <div>
                    <div className="mb-5">
                      <h2 className="text-xl text-slate-800 font-bold">
                        Найденные команды
                      </h2>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 lg:sidebar-expanded:grid-cols-2 xl:sidebar-expanded:grid-cols-4 gap-6">
                      {getFilteredCommands().map((command, index) => {
                        return (
                          <div
                            className="bg-slate-100 rounded-sm text-center p-5"
                            key={index}
                          >
                            <div className="flex flex-col h-full">
                              <div className="grow mb-2">
                                <div className="inline-flex w-12 h-12 rounded-full bg-indigo-400">
                                  <svg
                                    className="w-12 h-12"
                                    viewBox="0 0 48 48"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <defs>
                                      <linearGradient
                                        x1="50%"
                                        y1="0%"
                                        x2="50%"
                                        y2="100%"
                                        id="icon3-a"
                                      >
                                        <stop stopColor="#FFF" offset="0%" />
                                        <stop
                                          stopColor="#A5B4FC"
                                          offset="100%"
                                        />
                                      </linearGradient>
                                    </defs>
                                    <g fillRule="nonzero" fill="none">
                                      <circle
                                        fill="url(#icon3-a)"
                                        cx="30.5"
                                        cy="17.5"
                                        r="4.5"
                                      />
                                      <circle
                                        fill="#4F46E5"
                                        opacity=".88"
                                        cx="17.5"
                                        cy="17.5"
                                        r="4.5"
                                      />
                                      <circle
                                        fill="#4F46E5"
                                        opacity=".88"
                                        cx="30.5"
                                        cy="30.5"
                                        r="4.5"
                                      />
                                      <circle
                                        fill="url(#icon3-a)"
                                        cx="17.5"
                                        cy="30.5"
                                        r="4.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                                <h3 className="text-lg font-semibold text-slate-800 mb-1">
                                  {command.command}
                                </h3>
                                <div className="text-sm">
                                  {command.description}
                                </div>
                              </div>
                              <div className="flex justify-center space-x-10">
                                <button
                                  onClick={() => {
                                    setModalOpen(true);
                                    setSelectedCommand(command);
                                    setActionType(EActionType.VIEW);
                                  }}
                                  className="text-sm font-medium text-indigo-500 hover:text-indigo-600"
                                >
                                  Подробнее
                                </button>

                                {isAdmin && (
                                  <button
                                    onClick={() => {
                                      setModalOpen(true);
                                      setSelectedCommand(command);
                                      setActionType(EActionType.EDIT);
                                    }}
                                    className="text-sm font-medium text-indigo-500 hover:text-indigo-600"
                                  >
                                    Редактировать
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </main>
      </div>

      {selectedCommand &&
        actionType === EActionType.VIEW &&
        selectedCommand && (
          <ViewCommandModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            command={selectedCommand}
          />
        )}

      {selectedCommand &&
        actionType === EActionType.EDIT &&
        selectedCommand && (
          <EditCommandModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            command={selectedCommand}
            commands={commands}
          />
        )}
    </div>
  );
};

import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { FaCog } from 'react-icons/fa';

import { setSettingsOpen } from 'actions/actions';
import { selectCurrentAccount, selectSettings } from 'actions/selectors';

import UserDropdown from './Dropdown';

interface IProps {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const stateSelector = createStructuredSelector({
  currentAccount: selectCurrentAccount(),
  settings: selectSettings(),
});

const Header: React.FC<IProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const { currentAccount, settings } = useSelector(stateSelector);

  const isAdmin =
    (currentAccount && settings.admins.includes(currentAccount.vk.id)) || false;

  const dispatch = useDispatch();

  const handleOpenSettings = () => {
    dispatch(setSettingsOpen(true));
  };

  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          <div className="flex">
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>

          <div className="flex items-center space-x-3">
            {isAdmin && (
              <>
                <button
                  type="button"
                  className="text-gray-500 hover:bg-gray-100 focus:outline-none rounded-lg text-sm p-2.5"
                  onClick={handleOpenSettings}
                >
                  <FaCog className={`duration-500`} size="20" />
                </button>
              </>
            )}
            <hr className="w-px h-6 bg-slate-200 mx-3" />
            <UserDropdown />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import Flag from 'react-flagkit';

import { ICountry } from "types/web";

interface IProps {
    actualData: ICountry[];
}

export const SupportersChartCard: React.FC<IProps> = ({ actualData }) => {
    return <div className="flex flex-col col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
        <header className="px-5 py-4 border-b border-slate-100">
            <h2 className="font-semibold text-slate-800">Предоставленные Токены</h2>
        </header>

        <div className="flex flex-col h-full">
            <div className="px-5 py-3">
                <div className="flex items-center">
                    <div>
                        <div className="text-3xl font-bold text-slate-800 mr-2">{actualData.length}</div>
                        <div className="text-sm text-slate-500">Аккаунтов</div>
                    </div>
                </div>
            </div>

            <div className="grow px-5 pt-3 pb-1">
                <div className="overflow-x-auto max-h-36">
                    <table className="table-auto w-full">
                        <thead className="text-xs uppercase text-slate-400">
                            <tr>
                                <th className="py-2">
                                    <div className="font-semibold text-left">Страна</div>
                                </th>
                                <th className="py-2">
                                    <div className="font-semibold text-right">Количество Аккаунтов</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-slate-100">
                            {actualData.map((country, index) => {
                                return <tr key={index}>
                                    <td className="py-2">
                                        <div className="flex text-left">
                                            <Flag country={country.code} className="mr-2 hidden sm:block" />
                                            {country.code}
                                        </div>
                                    </td>
                                    <td className="py-2">
                                        <div className="font-medium text-right text-slate-800">{country.count}</div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}
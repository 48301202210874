import { FirebaseApp } from 'firebase/app';
import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { ILog } from 'types/logs';
import { IVkSession } from 'types/session';
import { ICommand, IVkAccount } from 'types/vk';

import {
  IAuthIP,
  IBotStatistics,
  IBotStatisticsHistory,
  ISettings,
} from 'types/web';

export default class FirestoreService {
  private firestore: Firestore;
  private vkAccountsRef: CollectionReference<DocumentData>;
  private commandsRef: CollectionReference<DocumentData>;
  private statisticsRef: CollectionReference<DocumentData>;
  private newsRef: CollectionReference<DocumentData>;
  private logsRef: CollectionReference<DocumentData>;

  constructor(firebaseApp: FirebaseApp) {
    this.firestore = getFirestore(firebaseApp);
    this.vkAccountsRef = collection(this.firestore, 'vkAccounts');
    this.commandsRef = collection(this.firestore, 'commands');
    this.statisticsRef = collection(this.firestore, 'statistics');
    this.newsRef = collection(this.firestore, 'news');
    this.logsRef = collection(this.firestore, 'logs');
  }

  public settingsSubscribe = (onChange: (settings: ISettings) => void) => {
    const unsubscribe = onSnapshot(
      doc(this.firestore, 'settings', 'web'),
      snapshot => {
        onChange(snapshot.data() as ISettings);
      },
    );

    return unsubscribe;
  };

  public statisticsSubscribe = (
    onChange: (settings: IBotStatistics) => void,
  ) => {
    const unsubscribe = onSnapshot(
      doc(this.firestore, 'statistics', 'bot'),
      snapshot => {
        onChange(snapshot.data() as IBotStatistics);
      },
    );

    return unsubscribe;
  };

  public async getStatisticsHistory(): Promise<IBotStatisticsHistory[]> {
    const q = query(
      this.statisticsRef,
      orderBy('createdAt', 'desc'),
      limit(10),
    );
    const docs = await (await getDocs(q)).docs;
    return docs.map(
      doc => ({ uid: doc.id, ...doc.data() } as IBotStatisticsHistory),
    );
  }

  public async updateSettings(webIsWorking: boolean): Promise<void> {
    await updateDoc(doc(this.firestore, 'settings', 'web'), { webIsWorking });
  }

  public vkAccountSubscribe = (
    id: number,
    onChange: (vkAccount: IVkAccount | undefined) => void,
  ) => {
    const q = query(this.vkAccountsRef, where('vk.id', '==', id));

    const unsubscribe = onSnapshot(q, snapshot => {
      onChange(
        snapshot.docs[0] ? (snapshot.docs[0].data() as IVkAccount) : undefined,
      );
    });

    return unsubscribe;
  };

  public async getVkAccounts(): Promise<IVkAccount[]> {
    // const q = query(this.vkAccountsRef, limit(5));
    // const snapshot = await getDocs(q);
    const snapshot = await getDocs(this.vkAccountsRef);

    return snapshot.docs.map(
      doc =>
        ({
          uid: doc.id,
          ...doc.data(),
        } as IVkAccount),
    );
  }

  public async getCommands(): Promise<ICommand[]> {
    const snapshot = await getDocs(this.commandsRef);

    return snapshot.docs.map(
      doc =>
        ({
          uid: doc.id,
          ...doc.data(),
        } as ICommand),
    );
  }

  public async getNews(): Promise<void> {
    const q = query(this.newsRef, orderBy('createdAt'), limit(1));

    const snapshot = await (await getDocs(q)).docs[0];
  }

  private async createVkSession(
    uid: string,
    id: number,
    ip: IAuthIP | null,
  ): Promise<void> {
    await setDoc(doc(this.firestore, 'vkSessions', String(id)), {
      sessions: [
        {
          uid,
          activity: moment().format('DD/MM/YYYY HH:mm:ss.SSS'),
          ip: ip?.ip || '?',
          country: ip?.country_name || '?',
          code: ip?.country_code || '?',
          city: ip?.city || '?',
        },
      ],
    });
  }

  public async updateVkSession(
    uid: string,
    id: number,
    ip: IAuthIP | null,
  ): Promise<void> {
    try {
      const accountSessions = (
        await getDoc(doc(this.firestore, 'vkSessions', String(id)))
      ).data() as IVkSession;

      if (!accountSessions) return await this.createVkSession(uid, id, ip);
      const selectedSession = accountSessions.sessions.find(s => s.uid === uid);

      if (selectedSession) {
        selectedSession.activity = moment().format('DD/MM/YYYY HH:mm:ss.SSS');
        selectedSession.ip = ip?.ip || '?';
        selectedSession.country = ip?.country_name || '?';
        selectedSession.city = ip?.city || '?';
        selectedSession.code = ip?.country_code || '?';
      } else
        accountSessions.sessions.push({
          uid,
          activity: moment().format('DD/MM/YYYY HH:mm:ss.SSS'),
          ip: ip?.ip || '?',
          country: ip?.country_name || '?',
          city: ip?.city || '?',
          code: ip?.country_code || '?',
        });

      await updateDoc(doc(this.firestore, 'vkSessions', String(id)), {
        ...accountSessions,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async getVkSession(
    id: number | undefined,
  ): Promise<IVkSession | undefined> {
    try {
      if (!id) return undefined;

      const accountSessions = (
        await getDoc(doc(this.firestore, 'vkSessions', String(id)))
      ).data() as IVkSession;
      return accountSessions;
    } catch (error) {
      return undefined;
    }
  }

  public async updateCommand(
    uid: string,
    name: string,
    caption: string,
    description: string,
    cooldown: number,
    isDisabled: boolean,
    canUseInDm: boolean,
    aliases: string[],
    usage: string[],
  ): Promise<void> {
    await updateDoc(doc(this.firestore, 'commands', uid), {
      command: name,
      caption,
      description,
      cooldown,
      isDisabled,
      canUseInDm,
      aliases,
      usage,
    });
  }

  public async getLogsByUserId(id: number): Promise<ILog[]> {
    try {
      const q = query(this.logsRef, where('id', '==', id));

      const snapshot = await (await getDocs(q)).docs;

      return snapshot.map(s => ({ uid: s.id, ...s.data() } as ILog));
    } catch (error) {
      return [];
    }
  }

  public async updateVkAccount(account: IVkAccount): Promise<boolean> {
    try {
      const uid = account.uid;
      if (!uid) return false;
      delete account.uid;

      await updateDoc(doc(this.firestore, 'vkAccounts', uid), { ...account });
      return true;
    } catch (error) {
      return false;
    }
  }
}

import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import Flag from 'react-flagkit';

import { firebaseApp } from 'firebaseApp';

import { setCurrentAccount, setLoading } from 'actions/actions';
import { selectCurrentAccount, selectSettings } from 'actions/selectors';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import NotWorked from 'components/NotWorked';

import { ISession, IVkSession } from 'types/session';
import { api } from 'api';

const stateSelector = createStructuredSelector({
  settings: selectSettings(),
  currentUser: selectCurrentAccount(),
});

const getSession = async (id: number): Promise<IVkSession | undefined> => {
  return await firebaseApp.firestore.getVkSession(id);
};

const Security = () => {
  const { settings, currentUser } = useSelector(stateSelector);

  const dispatch = useDispatch();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [session, setSession] = useState<IVkSession | undefined>();
  const [selectedSession, setSelectedSession] = useState<
    ISession | undefined
  >();

  useEffect(() => {
    if (currentUser) {
      dispatch(setLoading(true));
      getSession(currentUser.vk.id).then(session => setSession(session));
      dispatch(setLoading(false));
    }
  }, []);

  const handleCloseSession = async () => {
    if (currentUser && selectedSession) {
      dispatch(setLoading(true));
      await api.auth.deleteAuth(selectedSession.uid);
      if (currentUser?.uid === selectedSession.uid)
        await firebaseApp.auth.signOut();
      setSession(await getSession(currentUser.vk.id));
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        currentUser={currentUser}
      />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="h-full">
          {!settings.webIsWorking ? (
            <NotWorked />
          ) : (
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    Активные Сессии
                  </h1>
                </div>

                <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                  <span className="block" onClick={handleCloseSession}>
                    Удалить Сессию
                  </span>
                </button>
              </div>

              <div className="space-y-2">
                {session &&
                  session.sessions.map((session, index) => {
                    return (
                      <label
                        className="relative block cursor-pointer text-left w-full"
                        key={index}
                      >
                        <input
                          type="radio"
                          name="radio-buttons"
                          className="peer sr-only"
                          onClick={() => setSelectedSession(session)}
                        />
                        <div className="p-4 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
                          <div className="grid grid-cols-12 items-center gap-x-2">
                            <div className="col-span-6 order-1 sm:order-none sm:col-span-3 flex items-center space-x-4 lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-3">
                              <Flag
                                className="shrink-0"
                                country={session.code}
                                size={36}
                              />
                              <div>
                                <div className="text-sm font-medium text-slate-800">
                                  {session.country}, {session.city}
                                </div>
                                <div className="text-xs">
                                  {session.activity}
                                </div>
                              </div>
                            </div>
                            <div className="col-span-6 order-2 sm:order-none sm:col-span-3 text-left sm:text-center lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                              <div className="text-sm font-medium text-slate-800 truncate">
                                {session.uid}
                              </div>
                            </div>
                            <div className="col-span-6 order-1 sm:order-none sm:col-span-4 text-right sm:text-center lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-4">
                              <div className="text-sm">{session.ip}</div>
                            </div>
                            <div className="col-span-6 order-2 sm:order-none sm:col-span-2 text-right lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                              {currentUser?.uid === session.uid && (
                                <div className="text-xs inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                                  Текущая Сессия
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none"
                          aria-hidden="true"
                        />
                      </label>
                    );
                  })}
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Security;

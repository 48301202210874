import {
  selectCurrentAccount,
  selectIsGetAuth,
  selectSettings,
} from 'actions/selectors';
import Loader from 'components/Loader';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

interface IProps {
  type: 'auth' | 'admin';
}

const stateSelector = createStructuredSelector({
  isGetAuth: selectIsGetAuth(),
  currentAccount: selectCurrentAccount(),
  settings: selectSettings(),
});

export const PrivateRoute: React.FC<IProps> = ({ type }) => {
  const { isGetAuth, currentAccount, settings } = useSelector(stateSelector);

  const isAdmin =
    (currentAccount && settings.admins.includes(currentAccount.vk.id)) || false;

  if (!isGetAuth) return <Loader />;
  else {
    if (type === 'auth')
      return currentAccount ? <Outlet /> : <Navigate to="/" />;
    else if (type === 'admin')
      return isAdmin ? <Outlet /> : <Navigate to="/" />;

    return <Navigate to="/" />;
  }
};

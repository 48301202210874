import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentAccount } from 'actions/selectors';

import Header from 'components/Header';

import Sidebar from 'components/Sidebar';
import { UsersSidebar } from 'components/Users/UsersSidebar';
import {
  EVkAccountStatus,
  IDonator,
  IVkAccount,
  IVkAccountData,
} from 'types/vk';
import { setLoading } from 'actions/actions';
import { firebaseApp } from 'firebaseApp';
import { api } from 'api';
import { UsersBody } from 'components/Users/UsersBody';
import Modal from 'components/Modal';
import moment from 'moment';

const stateSelector = createStructuredSelector({
  currentUser: selectCurrentAccount(),
});

const declination = (value: number, words: string[]): string => {
  let returnValue = `${Math.ceil(value)} `;

  value = Math.abs(value) % 100;

  const number = value % 10;

  if (value > 10 && value < 20) returnValue += words[2];
  else if (number > 1 && number < 5) returnValue += words[1];
  else if (number === 1) returnValue += words[0];
  else returnValue += words[2];

  return returnValue;
};

export const Users = () => {
  const { currentUser } = useSelector(stateSelector);

  const dispatch = useDispatch();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showDonatorModal, setShowDonatorModal] = useState(false);
  const [UsersSidebarOpen, setUsersSidebarOpen] = useState(false);

  const [vkAccounts, setVkAccounts] = useState<IVkAccount[]>([]);
  const [vkAccountsData, setVkAccountsData] = useState<IVkAccountData[]>([]);

  const [selectedVkAccount, setSelectedVkAccount] = useState<
    IVkAccount | undefined
  >();
  const [selectedAccount, setSelectedAccount] = useState<
    IVkAccountData | undefined
  >();

  const [donateValue, setDonateValue] = useState(0);

  useEffect(() => {
    const getUsers = async () => {
      dispatch(setLoading(true));
      const vkAccounts = await firebaseApp.firestore.getVkAccounts();
      if (!vkAccounts.length) return;
      setVkAccounts(vkAccounts);
      const vkAccountsData = await api.vk.getUsers(
        vkAccounts.map(u => u.vk.id),
      );
      setVkAccountsData(vkAccountsData);
      dispatch(setLoading(false));
    };

    getUsers();
  }, []);

  useEffect(() => {
    setSelectedVkAccount(vkAccounts.find(a => a.vk.id === selectedAccount?.id));
  }, [selectedAccount]);

  const handleChangeBan = async (banUser: boolean) => {
    if (!selectedVkAccount) return;

    const newAccount = { ...selectedVkAccount };
    newAccount.vk.status = banUser
      ? EVkAccountStatus.BANNED
      : EVkAccountStatus.NORMAL;
    await firebaseApp.firestore.updateVkAccount({ ...newAccount });
    setSelectedVkAccount(newAccount);
  };

  const handleShowDonatorModal = () => {
    setShowDonatorModal(true);
  };

  const handleDeleteDonator = async () => {
    if (!selectedVkAccount) return;

    const newAccount = { ...selectedVkAccount };
    newAccount.vk.status = EVkAccountStatus.NORMAL;
    await firebaseApp.firestore.updateVkAccount({ ...newAccount });
    setSelectedVkAccount(newAccount);
  };

  const addDonator = async () => {
    if (!selectedVkAccount) return;
    const newDonate: IDonator = {
      amount: donateValue,
      start: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      end: moment()
        .add(Math.ceil(donateValue / 0.83), 'days')
        .format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
    };

    const newAccount = { ...selectedVkAccount };
    newAccount.vk.donator.push(newDonate);
    newAccount.vk.status = EVkAccountStatus.DONATOR;
    await firebaseApp.firestore.updateVkAccount({ ...newAccount });
    setSelectedVkAccount(newAccount);
    setShowDonatorModal(false);
    setDonateValue(0);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        currentUser={currentUser}
      />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="h-full">
          <div className="relative flex">
            <UsersSidebar
              open={UsersSidebarOpen}
              setOpen={setUsersSidebarOpen}
              vkAccounts={vkAccounts}
              vkAccountsData={vkAccountsData}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
            />
            <UsersBody
              usersSidebarOpen={UsersSidebarOpen}
              setUsersSidebarOpen={setUsersSidebarOpen}
              vkAccount={selectedVkAccount}
              selectedAccount={selectedAccount}
              actions={[
                handleChangeBan,
                handleShowDonatorModal,
                handleDeleteDonator,
              ]}
            />
          </div>
        </main>
      </div>

      <Modal
        id="feedback-modal"
        modalOpen={showDonatorModal}
        setModalOpen={setShowDonatorModal}
        title="Выдача Донатера"
      >
        <div className="pt-4 pb-1">
          <div>
            <div>
              <label className="block text-sm font-medium mb-1">
                Сумма Доната
              </label>
              <div className="relative">
                <input
                  className="form-input w-full pl-12"
                  type="number"
                  onChange={e => setDonateValue(Number(e.target.value))}
                />
                <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
                  <span className="text-sm text-slate-400 font-medium px-3">
                    RUB
                  </span>
                </div>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                Время Донатера
              </label>
              <input
                className="form-input w-full disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                type="text"
                placeholder={declination(donateValue / 0.83, [
                  'день',
                  'дня',
                  'дней',
                ])}
                disabled
              />
            </div>
          </div>
          <button className="mt-5 btn-sm w-full btn-green" onClick={addDonator}>
            {' '}
            Выдать Донатера{' '}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Users;

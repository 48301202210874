export enum EOsuServer {
  AKATSUKI = 'akatsuki',
  BANCHO = 'bancho',
  GATARI = 'gatari',
  KURIKKU = 'kurikku',
  RIPPLE = 'ripple',
}

export enum EOsuMode {
  OSU,
  TAIKO,
  CTB,
  MANIA,
}

export enum EOsuAccountStatus {
  NORMAL = 1,
  FAKE = 2,
  VERIFIED = 3,
}

export interface IOsu {
  mode: number;
  status: number;
  id: EOsuAccountStatus;
}

import superagent from 'superagent';
import { IAuthUser } from 'types/web';

import { firebaseApp } from 'firebaseApp';

const BASE_URL = 'https://europe-west2-seneal-bot.cloudfunctions.net/web';

export default class Auth {
  private firebase = firebaseApp;

  public async createAuth(code: string): Promise<string | number> {
    try {
      const response = await superagent
        .post(`${BASE_URL}/vk/create_auth`)
        .send({ code });
      if (response.status === 200 && response.body && response.body.token)
        return response.body.token;
      return 400;
    } catch (error) {
      return 400;
    }
  }

  public async getAuth(): Promise<IAuthUser | undefined> {
    try {
      const response = await superagent.get(
        `${BASE_URL}/vk/get_auth?token=${await this.firebase.auth.getToken()}`,
      );

      return response.body as IAuthUser;
    } catch (error) {
      return undefined;
    }
  }

  public async deleteAuth(uid: string): Promise<void> {
    await superagent.delete(`${BASE_URL}/vk/delete_auth`).send({
      token: await this.firebase.auth.getToken(),
      uid,
    });
  }

  public async createOsuAuth(code: string): Promise<number> {
    try {
      await superagent.post(`${BASE_URL}/osu/create_auth`).send({
        code,
        token: await this.firebase.auth.getToken(),
      });

      return 200;
    } catch (error) {
      return 400;
    }
  }

  public async deleteOsuAuth(): Promise<void> {
    await superagent.delete(`${BASE_URL}/osu/delete_auth`).send({
      token: await this.firebase.auth.getToken(),
    });
  }
}

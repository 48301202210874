import { Reducers, Reducer } from 'types/reducers';
import { ContainerState, ContainerActions } from './types';
import { ActionTypes } from './constants';

export const initialState: ContainerState = {
  isLoading: false,
  isGetAuth: false,
  settingsOpen: false,
  currentAccount: undefined,
  vkAccount: undefined,
  settings: {
    admins: [],
    webIsWorking: false,
  },
  online: [],
};

type ReducerActions = ContainerActions;

const reducers: Partial<Reducers<ReducerActions, ContainerState>> = {
  [ActionTypes.SET_LOADING]: payload => state => ({
    ...state,
    isLoading: payload.isLoading,
  }),
  [ActionTypes.SET_IS_GET_AUTH]: payload => state => ({
    ...state,
    isGetAuth: payload.isGetAuth,
  }),
  [ActionTypes.SET_SETTINGS_OPEN]: payload => state => ({
    ...state,
    settingsOpen: payload.isOpen,
  }),
  [ActionTypes.SET_CURRENT_ACCOUNT]: payload => state => ({
    ...state,
    currentAccount: payload.account,
  }),
  [ActionTypes.SET_VK_ACCOUNT]: payload => state => ({
    ...state,
    vkAccount: payload.account,
  }),
  [ActionTypes.SET_SETTINGS]: payload => state => ({
    ...state,
    settings: payload.settings,
  }),
  [ActionTypes.SET_ONLINE]: payload => state => ({
    ...state,
    online: payload.online,
  }),
};

function appReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  const { type, payload } = action;

  const reducer:
    | undefined
    | Reducer<typeof type, ContainerActions, ContainerState> = reducers[type];

  return reducer ? reducer(payload)(state) : state;
}

export default appReducer;

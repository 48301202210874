import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

export const selectGlobalDomain = (state: ApplicationRootState) =>
  state.global || initialState;

export const selectLoading = () =>
  createSelector(selectGlobalDomain, substate => substate.isLoading);

export const selectCurrentAccount = () =>
  createSelector(selectGlobalDomain, substate => substate.currentAccount);

export const selectVkAccount = () =>
  createSelector(selectGlobalDomain, substate => substate.vkAccount);

export const selectSettings = () =>
  createSelector(selectGlobalDomain, substate => substate.settings);

export const selectSettingsOpen = () =>
  createSelector(selectGlobalDomain, substate => substate.settingsOpen);

export const selectIsGetAuth = () =>
  createSelector(selectGlobalDomain, substate => substate.isGetAuth);

export const selectVkOnline = () =>
  createSelector(selectGlobalDomain, substate => substate.online);

import React from 'react';

import NoAvatar from '../../images/no_avatar.png';

const NotWorked = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto h-full flex items-center">
      <div className="max-w-2xl mx-auto my-auto">
        <div className="text-center px-4">
          <div className="inline-flex mb-8">
            <img src={NoAvatar} width="176" height="176" alt="?" />
          </div>
          <div className="mb-6">
            Сайт временно не работает.{' '}
            <span className="text-indigo-600 font-bold">
              Ведутся технические работы.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotWorked;

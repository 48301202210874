import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';

import Header from 'components/Header';
import LoaderBlock from 'components/Loader/block';

import NoAvatar from '../../images/no_avatar.png';

import { firebaseApp } from '../../firebaseApp';

import { api } from '../../api';

export const VkAuth = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [status, setStatus] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (!code) navigate('/');

    const auth = async () => {
      const status = await api.auth.createAuth(code as string);
      if (typeof status === 'string') {
        await firebaseApp.auth.signIn(status);
        return setStatus(200);
      }
      setStatus(status);
    };

    auth();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {status === 0 && <LoaderBlock />}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="h-full">
          <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto h-full flex items-center">
            <div className="max-w-2xl mx-auto my-auto">
              <div className="text-center px-4">
                <div className="inline-flex mb-8">
                  <img src={NoAvatar} width="176" height="176" alt="?" />
                </div>
                <div className="mb-6">
                  {status === 0 && (
                    <span className="text-indigo-600 font-bold">
                      Производится авторизация через Вконтакте, пожалуйста,
                      подождите...
                    </span>
                  )}

                  {status === 200 && (
                    <div className="flex flex-col">
                      <span className="text-emerald-600 font-bold">
                        Авторизация выполнена успешно, теперь можно вернуться на
                        главную страницу
                      </span>

                      <a
                        href="/"
                        className="btn mt-5 w-64 mx-auto bg-indigo-500 hover:bg-indigo-600 text-white"
                      >
                        Главная
                      </a>
                    </div>
                  )}

                  {status === 400 && (
                    <div className="flex flex-col">
                      <span className="text-red-600 font-bold">
                        Не удалось авторизоваться, вернитесь на главную страницу
                        и попробуйте ещё раз
                      </span>

                      <a
                        href="/"
                        className="btn mt-5 w-64 mx-auto bg-indigo-500 hover:bg-indigo-600 text-white"
                      >
                        Главная
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
